#about {
  padding-top: 4rem; }
  @media only screen and (max-width: 992px) {
    #about {
      display: none; } }

#medium {
  display: none; }
  @media only screen and (max-width: 992px) {
    #medium {
      display: block; } }

.about_text {
  z-index: 10; }
  .about_text h2 {
    font-family: PlainThin;
    font-size: 4rem;
    color: #5F8289;
    padding-top: 9rem; }
    @media only screen and (max-width: 992px) {
      .about_text h2 {
        text-align: center;
        margin-top: 6rem; } }
  .about_text .about_content {
    padding: 3rem 2.5rem 5rem 0; }
    @media only screen and (max-width: 992px) {
      .about_text .about_content {
        padding: 3rem 5rem 22% 5rem; } }
    @media only screen and (max-width: 768px) {
      .about_text .about_content {
        padding: 3rem 5rem 8rem 5rem; } }
    .about_text .about_content p {
      font-size: 1.5rem;
      font-family: PlainThin; }
