#root .map {
  width: 50%;
  padding: 6rem 0 6rem 4rem; }
  @media only screen and (max-width: 768px) {
    #root .map {
      width: 100%;
      padding: 6rem 6rem 2rem; } }
  @media only screen and (max-width: 576px) {
    #root .map {
      padding: 3rem 3rem 2rem; } }

.contact_text {
  position: absolute;
  width: 50%;
  font-size: 2rem;
  color: #fff;
  top: 10rem;
  padding: 0 10rem;
  right: 0; }
  .contact_text h2 {
    font-family: PlainThin; }
  .contact_text p {
    font-family: PlainThin; }
  @media only screen and (max-width: 992px) {
    .contact_text {
      font-size: 1.8rem;
      padding: 0 8rem; } }
  @media only screen and (max-width: 768px) {
    .contact_text {
      position: relative;
      width: 100%;
      padding: 3rem 6rem;
      top: 0; } }
  .contact_text h2 {
    font-size: 4rem;
    margin-bottom: 4rem; }
    @media only screen and (max-width: 992px) {
      .contact_text h2 {
        font-size: 2.6rem; } }
