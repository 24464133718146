.MapWrap {
  width: 100%;
  position: relative;
  height: 40rem; }
  @media only screen and (max-width: 576px) {
    .MapWrap {
      height: 35rem; } }
  @media only screen and (max-width: 360px) {
    .MapWrap {
      height: 25rem; } }

.customAdressLink {
  color: #445e63;
  position: absolute;
  z-index: 30;
  font-size: 1.7rem;
  font-family: PlainThin;
  text-decoration: underline;
  bottom: 0.5rem;
  font-weight: 400;
  right: 1.6rem; }

.marker {
  transform: translate(-50%, -60%); }

.customAdressLink:hover {
  color: #b1955f; }

.gmnoprint {
  display: none; }

.gm-style-cc {
  display: none; }

.marker-leaflet {
  width: 50px;
  height: 50px;
  background-color: red;
}

.leaflet-container {
  height: 40rem;
  width: 100%;
  position: relative;
}
