.about_text_second {
  color: #efd5a4;
  font-family: PlainThin;
  font-size: 2.2rem;
  letter-spacing: -0.3px;
  padding: 3rem 1rem 3rem 0; }

.About_background {
  background: linear-gradient(to left, #445e63 0%, #5F8289 100%);
  background-size: cover;
  width: 100%;
  position: relative; }
  .About_background_img {
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url("../../../assets/svg/two.png");
    opacity: 0.1;
    max-width: 160rem; }

.about_img .aboutImg {
  position: absolute;
  height: auto;
  user-select: none;
  bottom: 0;
  left: 50%;
  transform: translate(-54%, 0);
  max-width: 118%;
  padding-right: 2rem; }
  @media only screen and (max-width: 768px) {
    .about_img .aboutImg {
      transform: translate(-50%, 0); } }

.about_small {
  display: none; }
  @media only screen and (max-width: 768px) {
    .about_small {
      display: block; } }
  .about_small .About_background {
    height: 80%;
    position: absolute;
    bottom: 0; }
  .about_small .About_background_img {
    background-position: center;
    background-size: cover; }
  .about_small .about_img img {
    position: relative; }
  .about_small .about_text_second {
    color: #333;
    font-family: PlainThin;
    padding: 0 5rem 8rem;
    font-weight: 600;
    font-size: 1.8rem; }

@media only screen and (max-width: 768px) {
  #about_wide {
    display: none; } }
