@font-face {
  font-family: 'PlainThin';
  src: url('fonts/PlainThin/Plain-Thin.eot?#iefix') format('embedded-opentype'),
       url('fonts/PlainThin/Plain-Thin.otf')  format('opentype'),
       url('fonts/PlainThin/Plain-Thin.woff') format('woff'),
       url('fonts/PlainThin/Plain-Thin.ttf')  format('truetype'),
       url('fonts/PlainThin/Plain-Thin.svg#Plain-Thin') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: sans-serif;
  color: #333;
}

::selection {
  background-color: #c8ae7e;
}

html {
  font-size: 62.5%; /* Now 10px = 1rem! */
  overflow-x: hidden;
  overflow-y: scroll;
}

#root {
  overflow: hidden;
}
