.header {
  height: 50rem;
  color: #5F8289;
  position: relative;
  pointer-events: none; }
  @media only screen and (max-width: 768px) {
    .header {
      height: calc(calc(var(--vh) * 1) - 9rem); } }
  @media only screen and (max-height: 390px) {
    .header {
      height: calc(calc(var(--vh) * 1) - 9rem); } }
  .header .containerFixed {
    position: fixed;
    z-index: 30;
    width: 100vw;
    height: 9rem;
    top: 0;
    left: 0; }
  .header .containerFluid {
    position: relative;
    max-width: 1200px;
    height: 9rem;
    margin: 0 auto; }
  .header .imgWrap {
    position: absolute;
    min-width: 56rem;
    z-index: 30;
    bottom: -7rem;
    right: -10rem; }
    @media only screen and (max-width: 992px) {
      .header .imgWrap {
        right: -14rem;
        min-width: 50rem; } }
    @media only screen and (max-width: 768px) {
      .header .imgWrap {
        min-width: 45rem;
        right: -10rem; } }
    @media only screen and (max-width: 576px) {
      .header .imgWrap {
        min-width: 40rem;
        right: -10rem; } }
    @media only screen and (max-width: 360px) {
      .header .imgWrap {
        min-width: 35rem;
        right: -10rem; } }
    @media only screen and (max-height: 390px) {
      .header .imgWrap {
        min-width: 42rem;
        right: -10rem; } }
  .header .stickyImgWrap {
    position: absolute;
    min-width: 56rem;
    max-height: 589.25px;
    top: -52rem;
    right: -9.2rem; }
    @media only screen and (max-width: 992px) {
      .header .stickyImgWrap {
        min-width: 50rem;
        top: -45.6rem;
        right: -10rem; } }
    @media only screen and (max-width: 768px) {
      .header .stickyImgWrap {
        min-width: 45rem;
        top: -40.4rem;
        right: -10rem; } }
    @media only screen and (max-width: 576px) {
      .header .stickyImgWrap {
        min-width: 40rem;
        top: -35.1rem; } }
    @media only screen and (max-width: 360px) {
      .header .stickyImgWrap {
        min-width: 35rem;
        top: -30rem; } }
  .header .header_text {
    width: 50%;
    position: relative;
    z-index: 40;
    margin-left: 3rem; }
    @media only screen and (max-width: 768px) {
      .header .header_text {
        width: 100%;
        padding-right: 8rem;
        margin-left: 2.4rem; } }
    .header .header_text h1 {
      font-size: 6.5rem;
      font-family: PlainThin;
      padding-top: 5rem;
      padding-bottom: 6rem;
      font-weight: initial; }
      .header .header_text h1 .logo-big {
        font-weight: bold;
        color: #5F8289; }
      .header .header_text h1 .logo-small {
        font-size: 4.1rem;
        color: #63666A; }
      @media only screen and (max-height: 390px) {
        .header .header_text h1 {
          font-size: 3rem;
          padding-top: 2rem;
          padding-bottom: 2rem; } }
      @media all and (max-width: 500px) {
        .header .header_text h1 {
          font-size: 4.5rem; }
          .header .header_text h1 .logo-small {
            font-size: 3.5rem; } }
      @media all and (max-width: 400px) {
        .header .header_text h1 {
          font-size: 3.5rem; }
          .header .header_text h1 .logo-small {
            font-size: 2.4rem; } }
    .header .header_text h2 {
      font-size: 3rem;
      font-family: PlainThin;
      font-weight: 300;
      padding-bottom: 1rem; }
      @media only screen and (max-width: 992px) {
        .header .header_text h2 {
          font-size: 2.6rem; } }
      @media only screen and (max-width: 576px) {
        .header .header_text h2 {
          font-size: 2rem; } }
      @media only screen and (max-height: 390px) {
        .header .header_text h2 {
          font-size: 2.2rem; } }
    .header .header_text .subtitle {
      font-size: 1.8rem;
      font-family: PlainThin;
      font-weight: 500;
      padding-top: 1rem; }
      @media only screen and (max-width: 992px) {
        .header .header_text .subtitle {
          font-size: 1.5rem; } }
      @media only screen and (max-height: 390px) {
        .header .header_text .subtitle {
          font-size: 1.4rem; } }

.header_image_opacity {
  animation: opacity 0.3s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards; }

@media only screen and (max-height: 590px) {
  .header {
    height: calc(calc(var(--vh) * 1) - 9rem); } }

@keyframes opacity {
  from {
    opacity: 1; }
  to {
    opacity: 0.1; } }
