.member {
  flex-flow: row-reverse;
  flex-wrap: inherit;
  z-index: 30;
  font-family: PlainThin;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .member {
      flex-flow: column-reverse; } }

.member_text {
  align-self: center;
  color: #333; }
  @media only screen and (max-width: 768px) {
    .member_text {
      max-width: 90% !important; } }
  .member_text h3 {
    text-align: left;
    font-size: 2rem;
    font-family: PlainThin;
    font-weight: 500;
    padding-bottom: 2rem;
    padding-left: 7.5rem; }
    @media only screen and (max-width: 768px) {
      .member_text h3 {
        padding-left: 0; } }
  .member_text p {
    font-size: 1.5rem;
    font-family: PlainThin;
    padding-left: 7.5rem; }
    @media only screen and (max-width: 768px) {
      .member_text p {
        padding-left: 0; } }

.member_img {
  z-index: 2;
  padding-top: 8rem;
  user-select: none; }
  @media only screen and (max-width: 768px) {
    .member_img {
      align-self: center;
      padding: 6rem 0 4rem; } }
  .member_img .cv {
    position: absolute !important;
    right: -4rem;
    bottom: 0;
    display: none; }
    @media only screen and (max-width: 768px) {
      .member_img .cv {
        display: block;
        bottom: 3rem;
        right: -7rem; } }
    @media only screen and (max-width: 576px) {
      .member_img .cv {
        right: 0; } }

.member_heading {
  text-align: center;
  font-family: PlainThin;
  font-size: 4rem;
  color: #5F8289;
  padding: 6rem 10rem 0rem; }
  @media only screen and (max-width: 992px) {
    .member_heading {
      padding: 6rem 10rem 5rem; } }

.member_img:hover .cv {
  display: block; }

.member .row :last-child {
  padding-bottom: 4rem; }
