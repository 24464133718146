.mobile_paragh {
  display: none; }
  @media only screen and (max-width: 768px) {
    .mobile_paragh {
      display: block; } }
  @media only screen and (max-width: 768px) {
    .mobile_paragh {
      font-size: 1.5rem;
      text-align: left;
      margin: 0 auto;
      padding-bottom: 4rem; } }

.form_group {
  width: 50%;
  padding: 0 0 4rem 4rem; }
  @media only screen and (max-width: 768px) {
    .form_group {
      width: 100%;
      padding: 2rem 6rem 6rem; } }
  @media only screen and (max-width: 576px) {
    .form_group {
      padding: 2rem 3rem 6rem; } }
  .form_group label {
    position: relative;
    font-size: 1.8rem;
    font-family: PlainThin;
    width: 100%; }
    @media only screen and (max-width: 768px) {
      .form_group label {
        font-size: 1.5rem; } }
  .form_group input {
    font-size: 2rem;
    height: 5rem;
    border: 1px solid #333;
    border-radius: 0 !important; }
  .form_group textarea {
    height: 15rem !important;
    font-size: 2rem;
    border: 1px solid black;
    border-radius: 0; }
  .form_group .right {
    position: absolute !important;
    width: 50%;
    height: 100%;
    padding-left: 6rem;
    right: 0;
    bottom: 3.85rem; }
    @media only screen and (max-width: 768px) {
      .form_group .right {
        position: relative !important;
        width: 100%;
        padding-left: 0;
        bottom: 0; } }
    .form_group .right label {
      margin-top: 22.7rem;
      padding-bottom: 2rem; }
      @media only screen and (max-width: 768px) {
        .form_group .right label {
          display: none; } }
    .form_group .right button {
      width: 25rem;
      height: 5rem;
      background-color: #445e63;
      outline: none;
      border: none;
      cursor: pointer;
      user-select: none; }
      @media only screen and (max-width: 768px) {
        .form_group .right button {
          width: 100%;
          margin-top: 3rem; } }
    .form_group .right button:disabled {
      cursor: no-drop; }

#formInput {
  width: 100%; }

.is-invalid-input:focus {
  outline: #dc3545 auto 5px; }

.form-error {
  position: absolute;
  top: 50%;
  right: 1rem;
  color: #dc3545; }
