.ContactWrap .contact_text h2 {
  font-size: 2.5rem; }

.ContactWrap .contact_text .terms {
  margin-top: 25px; }
  .ContactWrap .contact_text .terms .modal_button {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    font-size: 16px;
    color: #efd5a4;
    cursor: pointer; }

.terms_of_use_modal .modal-content {
  border-radius: 0;
  border: none; }
  .terms_of_use_modal .modal-content .modal-header {
    background-color: #445e63;
    background-image: url("../../../assets/svg/three-white.png");
    background-position-x: 331%;
    background-position-y: 110%;
    background-size: 102%;
    border-radius: 0; }
    .terms_of_use_modal .modal-content .modal-header .close {
      color: #fff; }
  .terms_of_use_modal .modal-content p {
    font-size: 1.6rem; }
