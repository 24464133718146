.spinner {
  margin: 10rem auto 0;
  width: 17rem;
  text-align: center; }

.spinner > div {
  width: 3rem;
  height: 3rem;
  background-color: #445e63;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both; }

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
