html body {
  color: #63666A; }

.App {
  text-align: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

.container-fluid {
  max-width: 1200px !important; }

.col {
  padding: 0 !important; }

.topMargin {
  margin-top: 9rem; }

.IndexWrap {
  z-index: 30; }

.HeaderWrap {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 50rem;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .HeaderWrap {
      height: calc(calc(var(--vh) * 1) - 9rem); } }
  @media only screen and (max-height: 390px) {
    .HeaderWrap {
      height: calc(calc(var(--vh) * 1) - 9rem); } }
  .HeaderWrap .HeaderWrapBackgroundPlaceholder {
    background: linear-gradient(to left, #b1955f 0%, #fdebca 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }

.NavWrap {
  background-color: #5F8289;
  height: 9rem;
  position: relative;
  z-index: 40; }

.LawyersWrap {
  position: relative; }
  .LawyersWrap .Lawyers_background {
    background-image: url("./assets/svg/three.png");
    background-position-x: 10%;
    background-position-y: 40%;
    position: absolute;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    opacity: 0.1;
    bottom: 0; }
    @media only screen and (max-width: 992px) {
      .LawyersWrap .Lawyers_background {
        background-position-x: -32rem; } }
    @media only screen and (max-width: 768px) {
      .LawyersWrap .Lawyers_background {
        background-position-y: -2%;
        background-position-x: -52rem; } }
    @media only screen and (max-width: 576px) {
      .LawyersWrap .Lawyers_background {
        display: none; } }

.background {
  background: url("./assets/svg/three.png"); }

.TeamWrap {
  background-color: #F3F3F3;
  padding-bottom: 3rem; }

.Support_background {
  background-image: url("./assets/svg/for2.png");
  position: absolute;
  height: 100%;
  width: 100%;
  height: 100%;
  background-position-y: 8rem;
  background-position-x: 50rem;
  background-repeat: no-repeat;
  opacity: 0.1;
  bottom: 0;
  top: 0;
  z-index: 10; }
  @media only screen and (min-width: 1200px) {
    .Support_background {
      background-position-y: 8rem;
      background-position-x: 90rem; } }
  @media only screen and (max-width: 992px) {
    .Support_background {
      background-position-y: 5rem;
      background-position-x: 50rem; } }
  @media only screen and (max-width: 768px) {
    .Support_background {
      background-position-y: 2rem;
      background-position-x: 15rem; } }
  @media only screen and (max-width: 576px) {
    .Support_background {
      display: none; } }

.SupportWrap {
  position: relative;
  background-color: #F3F3F3; }

.FormWrap h2 {
  text-align: center;
  padding: 6rem 0 3rem;
  font-family: PlainThin;
  font-size: 4rem;
  color: #5F8289; }
  @media only screen and (max-width: 360px) {
    .FormWrap h2 {
      font-size: 3rem; } }

.ContactWrap {
  position: relative;
  background: linear-gradient(to left, #445e63 0%, #5F8289 100%);
  background-size: cover;
  width: 100%; }
  .ContactWrap .Contact_background {
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 160rem;
    opacity: 0.2;
    background-image: url("./assets/svg/five.png");
    background-position: center;
    background-size: cover; }

#section-2,
#section-3,
#section-4,
#section-5 {
  padding-top: 9rem;
  margin-top: -9rem; }

#section-1 {
  margin-top: -9rem; }

#section-2 {
  padding-bottom: 4rem; }

#section-3,
#section-4 {
  position: relative;
  z-index: 10; }

.sticky {
  position: fixed !important;
  top: 0 !important;
  bottom: auto;
  width: 100%;
  z-index: 20; }

.hideBackToTop {
  display: none; }

.showBackToTop {
  cursor: pointer;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  text-align: center;
  opacity: 0.5;
  z-index: 9999; }
  .showBackToTop .topIcon {
    width: 5rem;
    filter: sepia();
    opacity: 0.6; }
  .showBackToTop .topIcon:hover {
    transform: scale(1.05);
    transition: 0.2s ease; }

.Toastify__progress-bar {
  background: #5F8289 !important; }

@media only screen and (max-height: 590px) {
  .HeaderWrap {
    height: calc(calc(var(--vh) * 1) - 9rem); } }

.imageFadeOut {
  -webkit-mask-image: linear-gradient(to top, transparent 0%, black 3%);
  mask-image: linear-gradient(to top, transparent 0%, black 3%); }

.leaflet-pane.leaflet-shadow-pane {
  display: none;
}
