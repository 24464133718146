.support {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: center;
  padding: 0 2rem !important; }
  .support h3 {
    font-family: PlainThin;
    font-size: 1.8rem;
    color: #333; }
  .support .support_member {
    z-index: 20;
    display: flex;
    flex-direction: column-reverse;
    align-items: center; }
    @media only screen and (max-width: 360px) {
      .support .support_member {
        min-width: 22rem; } }
    @media only screen and (max-width: 576px) {
      .support .support_member h3 {
        text-align: center;
        padding-bottom: 1rem; } }
    .support .support_member img {
      margin: 2rem 0;
      user-select: none; }
    @media only screen and (max-width: 768px) {
      .support .support_member {
        max-width: 50%; } }
    @media only screen and (max-width: 576px) {
      .support .support_member {
        max-width: 80%; } }
    .support .support_member .cv {
      position: absolute !important;
      right: 10%;
      bottom: -1rem;
      display: none; }
      @media only screen and (max-width: 768px) {
        .support .support_member .cv {
          display: block;
          bottom: -1rem; } }
      @media only screen and (max-width: 576px) {
        .support .support_member .cv {
          right: 0; } }

.support_member:hover .cv {
  display: block; }
